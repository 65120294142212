import { StylesType } from "../utils/Types";

const styles: StylesType = {
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "2em",
    fontWeight: "bold",
    marginBottom: "0.2em",
    color: "white"
  }
};

type Props = {
  title: string,
  link: string,
  width: number,
  height: number,
  style?: React.CSSProperties,
};

const SongCard = ({
  title,
  link,
  width,
  height,
  style
}: Props) => {
  return (
    <div style={{ ...style, width, height: height + 50 }}>
      <div style={styles.title}>
        {title}
      </div>
      <iframe
        width={width}
        height={height}
        src={link}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default SongCard;