import { StylesType } from "../utils/Types";
import Nav from "./Nav";

const styles: StylesType = {
  container: {
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: "5em",
  }
};

type Props = {
  navColor?: string,
  children?: JSX.Element
}
const NavWithContent = ({
  navColor,
  children
}: Props) => {
  return (
    <div style={styles.container}>
      <Nav color={navColor ?? "white"} />
      <div style={styles.content}>
        {children}
      </div>
    </div>
  );
};

export default NavWithContent;