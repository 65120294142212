import React from "react";
import { StylesType, MusicVideoType } from "../utils/Types";
import { MUSIC_VIDEO_LIST } from "../utils/Constants";
import SongCard from "../components/SongCard";
import { Spinner } from "react-bootstrap";
import useWindowDimensions from "../utils/useWindowDimensions";

const styles: StylesType = {
  mediaContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: "5em",
  },
};

const aspectRatioConvert = 16 / 9;
const Media = () => {
  const { width } = useWindowDimensions();

  const cardWidth = Math.min(width, 500);
  const cardHeight = cardWidth / aspectRatioConvert;
  const toRender: Array<React.ReactNode> = [];
  MUSIC_VIDEO_LIST?.forEach((meta: MusicVideoType) => {
    toRender.push(
      <SongCard
        title={meta.title}
        link={meta.link}
        width={cardWidth}
        height={cardHeight}
        style={{ margin: "0.25em" }}
        key={meta.title}
      />
    );
  });

  return (
    <div style={styles.mediaContainer}>
      {toRender.length == 0 ? (
        <Spinner animation="border" variant="light" />
      ) : (
        toRender
      )}
    </div>
  );
};

export default Media;
