import { StylesType } from "../utils/Types";

export type BackgroundType =
  | "landing"
  | "a"
  | "b"
  | "c";

const backgrounds: {
  [B in BackgroundType]: string
} = {
  "landing": "https://imgur.com/IiKBZZj.jpg",
  "a": "https://imgur.com/mQlKzgo.jpg",
  "b": "https://imgur.com/uwI9MaI.jpg",
  "c": "https://imgur.com/LopeDEI.jpg"
};

const imgurLowerRes = (link: string) => {
  if (!link.includes("imgur"))
    console.error("Non imgur links are not supported");

  const parts = link.split(".");
  parts[parts.length - 2] += "l";
  return parts.join(".");
};

type Props = {
  background: BackgroundType,
  blur?: boolean,
  children?: JSX.Element
};
const BackgroundWrapper = ({
  background,
  blur,
  children
}: Props) => {
  const styles: StylesType = {
    appliedBackground: {
      backgroundImage: `url('${blur ? imgurLowerRes(backgrounds[background]) : backgrounds[background]}')`,
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    blur: {
      backdropFilter: `blur(${blur ? "3px" : "0px"})`,
      WebkitBackdropFilter: `blur(${blur ? "3px" : "0px"})`
    }
  };

  return (
    <div style={styles.appliedBackground}>
      <div style={styles.blur}>
        {children}
      </div>
    </div>
  );
};

export default BackgroundWrapper;