import { Link } from "react-router-dom";
import { StylesType } from "../utils/Types";
import "./Title.css";

const styles: StylesType = {
  title: {
    color: "white",
    textAlign: "center",
    textDecoration: "none",
    fontWeight: "bolder"
  }
};

type Props = {
  style?: React.CSSProperties | undefined,
  small?: boolean
}
const Title = ({
  style,
  small
}: Props) => {
  const smallStyling: React.CSSProperties = small ? {
    fontSize: "3em"
  } : {};
  return (
    <Link to="/" style={{...styles.title, ...style, ...smallStyling}} className="title-clamped">
      ZACK MORRIS
    </Link>
  );
};

export default Title;