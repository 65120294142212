import LinkContainer, { LinksType } from "../components/LinkContainer";
import Nav from "../components/Nav";
import Title from "../components/Title";
import { StylesType } from "../utils/Types";

const links: LinksType = [
  {
    service: "Spotify",
    imageUrl:
      "https://storage.googleapis.com/pr-newsroom-wp/1/2018/11/Spotify_Logo_CMYK_Green.png",
    link: "https://open.spotify.com/artist/02lC20x1HEHb2ndNmMbW93?si=jvMe7IrGTFWo6hiXKjz3yA",
  },
  {
    service: "Apple Music",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Apple_Music_logo.svg/1024px-Apple_Music_logo.svg.png?20190908141757",
    link: "https://music.apple.com/us/artist/zack-morris/1757566103",
  },
  // {
  //   service: "Amazon Music",
  //   imageUrl:
  //     "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.solfege.org%2Fwp-content%2Fuploads%2F2018%2F02%2Famazon-musique-1140x380.png&f=1&nofb=1",
  //   link: "https://music.amazon.com/artists/B0B721KTC8/zshim",
  // },
  {
    service: "Soundcloud",
    imageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Soundcloud_logo.svg/1280px-Soundcloud_logo.svg.png?20190525172110",
    link: "https://on.soundcloud.com/hL2rdvctPVrnRvoH7",
  },
  {
    service: "Youtube",
    imageUrl:
      "https://logodownload.org/wp-content/uploads/2014/10/youtube-logo-9.png",
    link: "https://youtube.com/@zackmorrismusicc?si=_v0ERNmyKT2ZRTOJ",
  },
];

const styles: StylesType = {
  container: {
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  email: {
    marginTop: "20px",
    fontSize: "16px",
    color: "white",
  },
};

const Landing = () => {
  return (
    <div style={styles.container}>
      <Title />
      <Nav color="white" />
      <LinkContainer links={links} />
      <div style={styles.email}>Contact: zackshimberg@icloud.com</div>
    </div>
  );
};

export default Landing;