import { NavLink } from "react-router-dom";
import { StylesType } from "../utils/Types";
import { pages } from "../utils/Pages";
import { Nav as BootstrapNav, Navbar } from "react-bootstrap";
import { useState } from "react";

const styles: StylesType = {
  container: {
    width: "100%"
  },
  link: {
    textDecoration: "none",
    color: "black",
    marginLeft: "1em",
    marginRight: "1em",
    fontSize: "1.5em",
    fontWeight: "bold",
  },
  underlined: {
    textDecoration: "underline"
  },
  toggle: {
    backgroundColor: "white",
    marginBottom: "0.5em"
  }
};

type Props = {
  color?: string,
}

const Nav = ({
  color,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const collapse = () => setExpanded(false);

  const items = pages.map(page =>
    <BootstrapNav.Item
      style={{ textAlign: "center" }}
      onClick={collapse}
      key={page}
    >
      <NavLink
        to={`/${page}`}
        style={
          ({ isActive }) => isActive ?
            {...styles.link, color, ...styles.underlined} :
            {...styles.link, color}
        }
      >
        {page.toUpperCase()}
      </NavLink>
    </BootstrapNav.Item>
  );

  return (
    <Navbar
      style={styles.container}
      className="justify-content-center"
      expand="sm"
      expanded={expanded}
      onToggle={(newExpanded) => setExpanded(newExpanded)}
    >
      <Navbar.Toggle style={styles.toggle}/>
      <Navbar.Collapse className="justify-content-center">
        <BootstrapNav className="justify-content-center">
          {items}
        </BootstrapNav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Nav;