import { FaGithub, FaLinkedin } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { StylesType } from "../utils/Types";

const styles: StylesType = {
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "10vw",
    width: "100vw",
    backgroundColor: "white",
  },
  copyright: {
    fontWeight: "100",
    fontStyle: "normal",
    fontSize: "small",
    padding: "20px",
  },
  socials: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  link: {
    padding: "10px",
    color: "black",
  },
};

const Footer = () => {
  return (
    <div style={styles.footer}>
      <div style={styles.copyright}>© 2022 by Tyler Hostler-Mathis.</div>
      <div style={styles.socials}>
        <a
          style={styles.link}
          href="https://www.github.com/tylerhm"
          target="__blank"
        >
          <FaGithub />
        </a>
        <a
          style={styles.link}
          href="https://www.linkedin.com/in/TylerHostlerMathis"
          target="__blank"
        >
          <FaLinkedin />
        </a>
        <a
          style={styles.link}
          href="mailto:tylerhm.dev@gmail.com"
          target="__blank"
        >
          <MdMail />
        </a>
      </div>
    </div>
  );
};

export default Footer;
