import emailjs from "./EmailJS";

const sendEmail = async (
  name: string,
  subject: string,
  message: string,
  replyTo: string
) => {
  const params = {
    name,
    subject,
    message,
    replyTo,
  };
  emailjs.send(
    "service_bo8aldp",
    "template_4erdpdq",
    params,
  );
};

export default sendEmail;