// Constants
import { MusicVideoListType } from "./Types";

export const MUSIC_VIDEO_LIST: MusicVideoListType = [
  {
    title: "Dumb In Love",
    link: "https://www.youtube.com/embed/ZPgOuQ7HnpM",
    uuid: "",
  },
  {
    title: "Trapped Inside My Room",
    link: "https://www.youtube.com/embed/h37ByBwU3lY",
    uuid: "",
  },
  {
    title: "No Big Deal",
    link: "https://www.youtube.com/embed/_UJz-SGahlA",
    uuid: "",
  },
  {
    title: "Overthinking",
    link: "https://www.youtube.com/embed/EvCWlJDdaco",
    uuid: "",
  },
  {
    title: "Feelin' Young",
    link: "https://www.youtube.com/embed/eijTiBEFfzE",
    uuid: "",
  },
  {
    title: "Don't Hate Me",
    link: "https://www.youtube.com/embed/_A6DBgAve5I",
    uuid: "",
  },
  {
    title: "Wasted Time",
    link: "https://www.youtube.com/embed/Ir-8jaf5nUw",
    uuid: "",
  },
];
