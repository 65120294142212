import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { pageMap } from "../utils/Pages";

function RouteControl() {

  const routes = [];
  for (const [path, element] of Object.entries(pageMap)) {
    routes.push(
      <Route
        path={`/${path}`}
        element={element}
        key={path}
      />
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {routes}
      </Routes>
    </BrowserRouter>
  );
}

export default RouteControl;
