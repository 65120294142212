import Footer from "../components/Footer";
import ContactMe from "../pages/ContactMe";
import Landing from "../pages/Landing";
import Media from "../pages/Media";
import Bio from "../pages/Bio";
import Title from "../components/Title";
import BackgroundWrapper, {
  BackgroundType,
} from "../components/BackgroundWrapper";
import NavWithContent from "../components/NavWithContent";

const injectFooter = (page: JSX.Element) => {
  return (
    <>
      {page}
      <Footer />
    </>
  );
};

const injectTitle = (page: JSX.Element) => {
  return (
    <>
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title
          style={{
            marginTop: "0.25em",
          }}
          small
        />
      </div>
      {page}
    </>
  );
};

const preparePage = (
  page: JSX.Element,
  onlyFooter?: boolean,
  background?: BackgroundType,
  blur?: boolean
) => {
  let pageContent = onlyFooter ? page : <NavWithContent>{page}</NavWithContent>;
  pageContent = injectFooter(pageContent);
  if (!onlyFooter) pageContent = injectTitle(pageContent);
  return (
    <BackgroundWrapper background={background ?? "landing"} blur={blur}>
      {pageContent}
    </BackgroundWrapper>
  );
};

export const pages = ["bio", "oldies", "contact"];
export const pageMap = {
  "": preparePage(<Landing />, true, "landing", true),
  landing: preparePage(<Landing />, true, "landing", true),
  bio: preparePage(<Bio />, false, "b", true),
  oldies: preparePage(<Media />, false, "c", true),
  contact: preparePage(<ContactMe />, false, "a", true),
};
