import React, { useState, useRef } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Form,
  Row,
  Col,
  Spinner,
  Overlay,
  Tooltip,
  Button
} from "react-bootstrap";
import {
  FaCheck,
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaTiktok,
  FaYoutube
} from "react-icons/fa";
import captchaVerify from "../utils/captchaVerify";
import sendEmail from "../utils/sendEmail";
import { StylesType } from "../utils/Types";

const styles: StylesType = {
  container: {
    margin: "1em"
  },
  title: {
    color: "white",
    textAlign: "center"
  },
  socials: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  social: {
    margin: "1em",
    marginBottom: "1.5em"
  },
  invis: {
    backgroundColor: "#00000000"
  },
  formRow: {
    justifyContent: "center"
  },
  status: {
    marginLeft: "0.75rem",
    width: "auto"
  },
  label: {
    fontSize: "1em",
    color: "white"
  },
  button: {
    margin: 6,
    alignSelf: "center",
    width: "fit-content"
  },
  submitRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
};

const mailSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  subject: yup.string().required(),
  content: yup.string().required(),
});

type MailDataType = {
  firstName: string,
  lastName: string,
  subject: string,
  content: string,
  email: string
};

interface RecaptchaRef {
  current: {
    executeAsync: () => Promise<string>
  }
}

interface TooltipRef {
  current: HTMLButtonElement | null
}

const EmailForm = () => {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [warn, setWarn] = useState(false);

  const recaptchaRef: RecaptchaRef = useRef() as RecaptchaRef;
  const tooltipRef: TooltipRef = useRef() as TooltipRef;

  const submit = (data: MailDataType)  => {
    const fullName = data.firstName + " " + data.lastName;
    const subject = data.subject;
    const message = data.content;
    const replyTo = data.email;
    sendEmail(fullName, subject, message, replyTo);
  };

  const verify = (data: MailDataType) => {
    if (sent) {
      setWarn(true);
      setTimeout(() => {
        setWarn(false);
      }, 5000);
      return;
    }
  
    // Bypass reCAPTCHA verification
    // if (recaptchaRef.current == null)
    //   return;
  
    setSending(true);
    // recaptchaRef.current.executeAsync()
    //   .then((token: string) => {
    //     captchaVerify(token)
    //       .then((score: number | void) => {
  
    //         // Ensure score is a number by providing a default value (e.g., 0) if it's undefined
    //         const numericScore = score ?? 0;
    //         if (numericScore < parseFloat(process.env.REACT_APP_GCAP_THRESHOLD as string))
    //           return;
  
    submit(data);
    setSending(false);
    setSent(true);
    //       }).catch(console.error);
    //   }).catch(console.error);
  };

  let status: React.ReactNode;
  if (sending) status = <Spinner style={styles.status} animation="border" variant="light" />;
  if (sent) status = <FaCheck style={styles.status} size={24} color="white" />;

  return (
    <div>
      <h1 style={styles.title}>
        Socials
      </h1>
      <div style={styles.socials}>
        <a
          style={styles.social}
          href="https://twitter.com/zackshimberg?lang=en"
          target="__blank">
          <FaTwitter color="white" />
        </a>
        <a
          style={styles.social}
          href="https://www.instagram.com/zshimusic/"
          target="__blank">
          <FaInstagram color="white" />
        </a>
        <a
          style={styles.social}
          href="https://www.facebook.com/Zshimusic"
          target="__blank">
          <FaFacebook color="white" />
        </a>
        <a
          style={styles.social}
          href="https://www.tiktok.com/@zshimusic?lang=en"
          target="__blank">
          <FaTiktok color="white" />
        </a>
        <a
          style={styles.social}
          href="https://www.youtube.com/channel/UCXdml3Gc9CIaF1dvfLSRagg?sub_confirmation=1"
          target="__blank"> 
          <FaYoutube color="white" />
        </a>
      </div>
      <h1 style={styles.title}>
        Email
      </h1>
      <Formik
        validationSchema={mailSchema}
        validateOnChange={false}
        onSubmit={verify}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          content: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
        }) => (
          <Form
            style={styles.container}
            noValidate
            onSubmit={handleSubmit}
          >

            {/* <ReCAPTCHA
              ref={recaptchaRef as React.LegacyRef<ReCAPTCHA> | undefined}
              // size="invisible"
              sitekey={process.env.REACT_APP_GCAP_SITE_KEY as string}
            /> */}
            
            <Row>
              <Form.Group as={Col} xs="6" md="6" controlId="validationFormik01">
                <Form.Label style={styles.label}>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a first name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs="6" md="6" controlId="validationFormik02">
                <Form.Label style={styles.label}>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a last name.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs="6" md="6" controlId="validationFormik03">
                <Form.Label style={styles.label}>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs="6" md="6" controlId="validationFormik04">
                <Form.Label style={styles.label}>Subject</Form.Label>
                <Form.Control
                  type="text"
                  name="subject"
                  value={values.subject}
                  onChange={handleChange}
                  isInvalid={!!errors.subject}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a subject.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs="12" md="12" controlId="validationFormik05">
                <Form.Label style={styles.label}>Content</Form.Label>
                <Form.Control
                  type="text"
                  name="content"
                  as="textarea"
                  rows={3}
                  value={values.content}
                  onChange={handleChange}
                  isInvalid={!!errors.content}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide some content.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row style={styles.submitRow}>
              <Button
                style={styles.button}
                type="submit"
                ref={tooltipRef}
              >
                Submit
              </Button>
              {status}
              <Overlay target={tooltipRef.current} show={warn} placement="left" transition={true}>
                {(props) => (
                  <Tooltip {...props} style={styles.status}>
                    Oops! Looks like you&apos;ve already sent an email.
                  </Tooltip>
                )}
              </Overlay>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmailForm;