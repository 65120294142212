import { StylesType } from "../utils/Types";
import "./Gallery.css";

const styles: StylesType = {
  gallery: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly"
  },
  container: {
    overflow: "hidden"
  }
};

const Gallery = () => {
  return (
    <div style={styles.gallery}>
      <a href="https://imgur.com/KfMa8JM" target="__blank" style={styles.container}>
        <img
          src="https://i.imgur.com/KfMa8JMh.jpg"
          alt="Gallery Headshot One"
          className="gallery-image"></img>
      </a>
      <a href="https://imgur.com/Ikh9xMx" target="__blank" style={styles.container}>
        <img
          src="https://i.imgur.com/Ikh9xMxh.png"
          alt="Gallery Headshot Two"
          className="gallery-image"></img>
      </a>
      <a href="https://imgur.com/YGycGCq" target="__blank" style={styles.container}>
        <img
          src="https://i.imgur.com/YGycGCqh.jpg"
          alt="Gallery Headshot Three"
          className="gallery-image"></img>
      </a>
    </div>
  );
};

export default Gallery;