import Gallery from "../components/Gallery";
import { StylesType } from "../utils/Types";
import useWindowDimensions from "../utils/useWindowDimensions";

const styles: StylesType = {
  container: {
    width: "100vw",
  },
  aboutMe: {
    color: "white",
    fontSize: "1.25em",
    fontWeight: "500",
    borderRadius: "4px",
    marginBottom: "25vh",
    marginLeft: "10vw",
  },
};

const Bio = () => {
  const { width } = useWindowDimensions();

  const clampedWidth: React.CSSProperties = {
    width: Math.min(400, width - 100),
  };

  return (
    <>
      <div style={styles.container}>
        <div style={{ ...styles.aboutMe, ...clampedWidth }}>
          <p>
            Zack Shimberg is a singer, songwriter from the city of Tampa, Florida. 
            Zack, better known as Zack Morris, gives a unique flair to the modern music industry by releasing and producing pieces among many different pop categories. Starting out as just a kid with big dreams, Zack Morris is quickly showing the world his larger-than-life print with his dedication and determination. 
            By transforming his life experiences from his young 23 years into lyrical pieces, he is captivating the attention of many in a relatable fashion, turning his big dreams into a reality. 
            Zack&apos;s love, passion, and strive to out-do every release with the next, truly proves, it&apos;s only up from here.
          </p>
        </div>
      </div>

      <div style={{ marginTop: "auto" }}>
        <Gallery />
      </div>
    </>
  );
};

export default Bio;
