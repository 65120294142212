import { StylesType } from "../utils/Types";
import { Button } from "react-bootstrap";
import "./LinkContainer.css";

const styles: StylesType = {
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fffa",
    borderRadius: "1em",
    marginTop: "2em"
  },
  element: {
    width: "100%",
    height: "5em",
    padding: "1em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderColor: "#bbba",
    borderWidth: "2px"
  }
};

export type LinksType = Array<{
  service: string,
  imageUrl: string,
  link: string
}>;

type Props = {
  links?: LinksType
}
const LinkContainer = ({
  links
}: Props) => {
  const renderables = links?.map((item, index) => {
    const borderStyle: React.CSSProperties = {
      borderBottom: index == links.length - 1 ? "none" : "solid",
    };
    return (
      <div style={{...styles.element, ...borderStyle}} key={item.service}>
        <img style={{ maxWidth: "75%", maxHeight: "90%" }} src={item.imageUrl} alt={item.service} />
        <Button href={item.link} target="__blank">
          Visit
        </Button>
      </div>
    );
  });
  return (
    <div style={styles.container} className="clamped-link-container">
      {renderables}
    </div>
  );
};

export default LinkContainer;